const Footer = () => {
  return (
    <footer className="footer">
      <p>&copy; 2024 SimonDevs, created by Simon Deeb</p>
      <ul>
        <li><a href="https://www.linkedin.com/in/simon-deeb-6970a6211">Linkedin</a></li>
        <li><a href="https://github.com/magusdevs">Github</a></li>
      </ul>
    </footer>
  );
};

export default Footer;